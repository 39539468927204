<template>
  <section class="main">
  <div class="mid-main-character">
        <div class="mid-main-letter">
          <img v-show="selectedLetterActive" :src="require(`../assets/img/letters/big-letters/${selectedLetterImage}.png`)" />
          <div v-show="selectedLetterActive" class="approved"  @click.prevent="nextpage()"><a href="#" class="active"><img src="../assets/img/approved.png" alt="" /></a></div>
          <div v-show="selectedLetterActive" class="cancelled" @click.prevent="clearSelectedLetter()"><a href="#" class="active"><img src="../assets/img/cancelled.png" alt="" /></a></div>
        </div>
      </div>
    <div class="stage"><a href="#" class="logo">logo</a></div>
     <div class="back-arrow nav-arrow">
        <a href="javascript:void(0)" @click.prevent="backpageOfIntro()"><img src="../assets/img/back-arrow.png" alt="" /></a>
      </div>
      <div class="forward-arrow nav-arrow">
         <a href="#"><img src="../assets/img/forward-arrow.png" alt="" /></a>
      </div>
    <div class="bottom"> 
    <!-- Footer -->
    <div class="footer-letters" dir="rtl">
      <div class="slider-letter">
        <div class="footer-letters-box" v-for="(slickthumbImage, index) in slickthumbImages" :key="index">
        <a href="#" @click.prevent="playSound(slickthumbImage.selectedId,slickthumbImage.audio)"><img :src="slickthumbImage.nimage" alt=""> <img :src="slickthumbImage.simage" alt=""></a> </div>
      </div>
    </div>
    <!-- Footer --> 
  </div>
  </section>
</template>
<script>
// import jQuery from 'jquery'
import Slick from 'vue-slick';
export default {
  name: "Letters",
  data() {
    return {
      selected_pose: "",
      Slick,
      slickthumbImages:[
        {
          id:1,
          selectedId:1,
          nimage: require("../assets/img/letters/hover/1.png"),
          simage: require("../assets/img/letters/hover/selected/1.png"),
          audio:  "../assets/sound/wav/א.wav",
        },
        {
          id:2,
          selectedId:2,
          nimage: require("../assets/img/letters/hover/2.png"),
          simage: require("../assets/img/letters/hover/selected/2.png"),
          audio:  "../assets/sound/wav/ב_.wav"
        },
        {
          id:3,
          selectedId:103,
          nimage: require("../assets/img/letters/hover/2_Point_Brown.png"),
          simage: require("../assets/img/letters/hover/selected/2_Point_Brown_Hover.png"),
          audio:  "../assets/sound/wav/ב.wav"
        },
        {
          id:4,
          selectedId:3,
          nimage: require("../assets/img/letters/hover/3.png"),
          simage: require("../assets/img/letters/hover/selected/3.png"),
          audio:  "../assets/sound/wav/ג.wav"
        },
        {
          id:5,
          selectedId:4,
          nimage: require("../assets/img/letters/hover/4.png"),
          simage: require("../assets/img/letters/hover/selected/4.png"),
          audio:  "../assets/sound/wav/ד.wav"
        },
        {
          id:6,
          selectedId:5,
          nimage: require("../assets/img/letters/hover/5.png"),
          simage: require("../assets/img/letters/hover/selected/5.png"),
          audio:  "../assets/sound/wav/5.ogg"
        },
        {
          id:7,
          selectedId:6,
          nimage: require("../assets/img/letters/hover/6.png"),
          simage: require("../assets/img/letters/hover/selected/6.png"),
          audio:  "../assets/sound/wav/ו.wav"
        },
        {
          id:8,
          selectedId:7,
          nimage: require("../assets/img/letters/hover/7.png"),
          simage: require("../assets/img/letters/hover/selected/7.png"),
          audio:  "../assets/sound/wav/ז.wav"
        },
        {
          id:9,
          selectedId:8,
          nimage: require("../assets/img/letters/hover/8.png"),
          simage: require("../assets/img/letters/hover/selected/8.png"),
          audio:  "../assets/sound/wav/ח.wav"
        },
        {
          id:10,
          selectedId:9,
          nimage: require("../assets/img/letters/hover/9.png"),
          simage: require("../assets/img/letters/hover/selected/9.png"),
          audio:  "../assets/sound/wav/ט.wav"
        },
        {
          id:11,
          selectedId:10,
          nimage: require("../assets/img/letters/hover/10.png"),
          simage: require("../assets/img/letters/hover/selected/10.png"),
          audio:  "../assets/sound/wav/י.wav"
        },
        {
          id:12,
          selectedId:11,
          nimage: require("../assets/img/letters/hover/11.png"),
          simage: require("../assets/img/letters/hover/selected/11.png"),
          audio:  "../assets/sound/wav/כ.wav"
        },
        {
          id:13,
          selectedId:1011,
          nimage: require("../assets/img/letters/hover/11_Point_Brown.png"),
          simage: require("../assets/img/letters/hover/selected/11_Point_Brown.png"),
          audio:  "../assets/sound/wav/כ_.wav"
        },
        {
          id:14,
          selectedId:12,
          nimage: require("../assets/img/letters/hover/12.png"),
          simage: require("../assets/img/letters/hover/selected/12.png"),
          audio:  "../assets/sound/wav/ל.wav"
        },
        {
          id:15,
          selectedId:13,
          nimage: require("../assets/img/letters/hover/13.png"),
          simage: require("../assets/img/letters/hover/selected/13.png"),
          audio:  "../assets/sound/wav/מ.wav"
        },
        {
          id:16,
          selectedId:14,
          nimage: require("../assets/img/letters/hover/14.png"),
          simage: require("../assets/img/letters/hover/selected/14.png"),
          audio:  "../assets/sound/wav/נ.wav"
        },
        {
          id:17,
          selectedId:15,
          nimage: require("../assets/img/letters/hover/15.png"),
          simage: require("../assets/img/letters/hover/selected/15.png"),
          audio:  "../assets/sound/wav/ס.wav"
        },
        {
          id:18,
          selectedId:16,
          nimage: require("../assets/img/letters/hover/16.png"),
          simage: require("../assets/img/letters/hover/selected/16.png"),
          audio:  "../assets/sound/wav/ע.wav"
        },
        {
          id:19,
          selectedId:17,
          nimage: require("../assets/img/letters/hover/17.png"),
          simage: require("../assets/img/letters/hover/selected/17.png"),
          audio:  "../assets/sound/wav/פ_.wav"
        },
        {
          id:20,
          selectedId:1017,
          nimage: require("../assets/img/letters/hover/17_Point_Brown.png"),
          simage: require("../assets/img/letters/hover/selected/17_Point_Hover.png"),
          audio:  "../assets/sound/wav/פ.wav"
        },
        {
          id:21,
          selectedId:18,
          nimage: require("../assets/img/letters/hover/18.png"),
          simage: require("../assets/img/letters/hover/selected/18.png"),
          audio:  "../assets/sound/wav/צ.wav"
        },
        {
          id:22,
          selectedId:19,
          nimage: require("../assets/img/letters/hover/19.png"),
          simage: require("../assets/img/letters/hover/selected/19.png"),
          audio:  "../assets/sound/wav/ק.wav"
        },
        {
          id:23,
          selectedId:20,
          nimage: require("../assets/img/letters/hover/20.png"),
          simage: require("../assets/img/letters/hover/selected/20.png"),
          audio:  "../assets/sound/wav/ר.wav"
        },
        // removed 21 serial image
        {
          id:24,
          selectedId:1021,
          nimage: require("../assets/img/letters/hover/21_Point_Brown_Left.png"),
          simage: require("../assets/img/letters/hover/selected/21_Point_Left_Hover.png"),
          audio:  "../assets/sound/wav/ש_.wav"
        },
        {
          id:25,
          selectedId:1022,
          nimage: require("../assets/img/letters/hover/21_Point_Brown_Right.png"),
          simage: require("../assets/img/letters/hover/selected/21_Point_Right_Hover.png"),
          audio:  "../assets/sound/wav/ש.wav"
        },
        {
          id:26,
          selectedId:22,
          nimage: require("../assets/img/letters/hover/22.png"),
          simage: require("../assets/img/letters/hover/selected/22.png"),
          audio:  "../assets/sound/wav/ת.wav"
        },
      ],
      selectedLetterImage:1,
      selectedLetterActive:false
    };
  },
  mounted() {
    if (localStorage.name) {
      this.name = localStorage.name;
    }
    //require our external library and load it in window
      // window.owl = require('owl-carousel')
      window.$ = require('jquery')

      const script = document.createElement("script")
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"
      script.async = true
      document.body.appendChild(script)

      // part 2

      const script2 = document.createElement("script")
      script2.src = "../assets/js/slick.min.js"
      script2.async = true
      document.body.appendChild(script2)

      // part 3

      const script3 = document.createElement("script")
      script3.src = "../assets/js/main.js"
      script3.async = true
      document.body.appendChild(script3);

      this.manSlickJsLoader();

  },
  methods: {
    playSound (type,sound) {
    console.log("selection - ",type);
      if(sound) {
        var audio = new Audio(sound);
        audio.play();
        localStorage.setItem("selected_pose", type);
        localStorage.selected_pose = type;

        // image name converting statically
        if(type==1022){
        this.selectedLetterImage ='21_Point_Right';
        } else if(type==1021){
            this.selectedLetterImage ='21_Point_Left';
        } else if(type==1017){
            this.selectedLetterImage ='17_Point';
        } else if(type==1011){
            this.selectedLetterImage ='11_Point';
        } else if(type==103){
            this.selectedLetterImage ='2_Point_Brown';
        }else{
            this.selectedLetterImage = type;
        }
        // end
        this.selectedLetterActive=true;
        console.log(type);
        // setTimeout(() => this.$router.push({ path: "/character" }), 500);
      }
    },
    nextpage (){
      this.charactorPageForSound(3000);
      setTimeout(() => this.$router.push({ path: "/character" }), 500);
    },
    backpageOfIntro (){
      setTimeout(() => this.$router.push({ path: "/intro" }), 100);
    },
    manSlickJsLoader (){
      var $ = require( "jquery" );
      $(window).on('load resize', function(){
          var setHeight = $('.bottom:visible').innerHeight() +  "px"; 
          $('.footer-letters-box').css('height',setHeight);
          console.log("settings call ---",setHeight);
          });
        $('.slider-letter').slick({
          rtl:true,
          centerMode: false,
          infinite: true,
          autoplay: false,
          autoplaySpeed: 3000,
          centerPadding: '0px',
          slidesToShow:6, // Shows a three slides at a time
          slidesToScroll: 1, // When you click an arrow, it scrolls 1 slide at a time
          arrows: true, // Adds arrows to sides of slider
          dots:false, // Adds the dots on the bottom
          focusOnSelect: true,
        });
    },
    charactorPageForSound (timeDefine=3000){
      setTimeout(() =>{
          var soundurl = "../assets/sound/common/bg_character.wav";
          var audio = new Audio(soundurl);
              audio.play();
        }, timeDefine);
    },
    clearSelectedLetter(){
      this.selectedLetterActive=false;
    }
  },
  watch: {
    name(newName) {
      localStorage.name = newName;
    },
  },      
};
</script>
<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick-theme.min.css";
</style>

<template>
  <section class="main">
    <!-- <div class="back-button" @click.prevent="button_actions()"></div> -->

     <div class="back-arrow nav-arrow">
        <a href="/letters"><img src="../assets/img/back-arrow.png" alt="" /></a>
      </div>
      <div class="forward-arrow nav-arrow">
         <a href="#"><img src="../assets/img/forward-arrow.png" alt="" /></a>
      </div>


    <div class="character-stage">
      <div class="character-stage-poses">
        <div
          class="small-poses"
          v-for="(smallNinjaImg, index) in thumbsForTop"
          :key="index"
          :id="'reserve' + index"
        >
          <a href="#"><img :src="smallNinjaImg.image" /></a>
          <div class="cross" v-on:click="removeSelection(smallNinjaImg,index)">
            <img src="../assets/img/cancel.png" alt="" />
          </div>
        </div>
      </div>
      <div class="mid-main-character">
        <div
          class="playpause"
          v-show="isSelectionProcessCompleted"
          style="width: 220px; margin-top: 8%"
          @click.prevent="dancingFace()"
        >
         <img src="../assets/img/PlayButton.png" class="playpausenormal" alt="" />
         <img src="../assets/img/PlayButton_hover.png" class="playpausehover" alt="" />
        
        </div>
        <!-- <img v-show="isSelectionProcessCompleted" :src="require(`../assets/img/PlayButton.png`)" style="cursor: pointer;position: absolute;width: 150px; margin: -70px 0 0 -67px;" @click.prevent="dancingFace()" /> -->
        <div class="mid-main-letter"  v-if="hideSelectedLetterIfSelectionCompleted">
          <img
            :src="
              require(`../assets/img/letters/big-letters/${active_letter}.png`)
            "
           
          />
        </div>
        <div class="mid-main-letter" style="height: 600px;"  v-else></div>
      </div>
    </div>
    <div class="bottom2">
      <!-- Footer -->
      <div class="footer-letters2">
        <div class="footer-poses">
          <div
            class="footer-poses-box"
            v-for="(bigNinjaImg, index) in thumbsForButtom"
            :key="index"
            v-on:click="
              characters(
                bigNinjaImg.clickId,
                bigNinjaImg.audio,
                bigNinjaImg.nimage,
                bigNinjaImg.dancinggif
              )
            "
          >
            <img :src="bigNinjaImg.nimage" alt="btn" />
            <img :src="bigNinjaImg.simage" alt="" />
          </div>
        </div>
      </div>
      <!-- Footer -->
    </div>
  </section>
</template>
<script>
global.jQuery = require("jquery");
export default {
  name: "Character",
  data() {
    return {
      selectedposes_array: [],
      selectedposesWithSound_array: [],
      isLoaded: false,
      isSelectionProcessCompleted: false,
      hideSelectedLetterIfSelectionCompleted: true,
      active_letter: " ",
      removedSelection: [],
      selectedActions: [],
      thumbsForTop: [],
      thumbsForButtom: [
        {
          id: 1,
          clickId: 2,
          nimage: "../assets/img/small-poses/AH_default.png",
          simage: "../assets/img/small-poses/AH_hover.png",
          audio:
            "../assets/sound/ninja/" +
            localStorage.getItem("selected_pose") +
            "/2.mp3",
          dancinggif:"../assets/img/small-poses/AH_default.gif",
        },
        {
          id: 2,
          clickId: 6,
          nimage: "../assets/img/small-poses/EE_default.png",
          simage: "../assets/img/small-poses/EE_hover.png",
          audio:
            "../assets/sound/ninja/" +
            localStorage.getItem("selected_pose") +
            "/6.mp3",
          dancinggif:"../assets/img/small-poses/EE_default.gif",
        },
        {
          id: 3,
          clickId: 1,
          nimage: "../assets/img/small-poses/EH_default.png",
          simage: "../assets/img/small-poses/EH_hover.png",
          audio:
            "../assets/sound/ninja/" +
            localStorage.getItem("selected_pose") +
            "/1.mp3",
          dancinggif:"../assets/img/small-poses/EH_default.gif",
        },
        {
          id: 4,
          clickId: 5,
          nimage: "../assets/img/small-poses/OH_default.png",
          simage: "../assets/img/small-poses/OH_hover.png",
          audio:
            "../assets/sound/ninja/" +
            localStorage.getItem("selected_pose") +
            "/5.mp3",
          dancinggif:"../assets/img/small-poses/OH_default.gif",
        },
        {
          id: 5,
          clickId: 4,
          nimage: "../assets/img/small-poses/U_default.png",
          simage: "../assets/img/small-poses/U_hover.png",
          audio:
            "../assets/sound/ninja/" +
            localStorage.getItem("selected_pose") +
            "/4.mp3",
          dancinggif:"../assets/img/small-poses/U_default.gif",
        },
        {
          id: 6,
          clickId: 3,
          nimage: "../assets/img/small-poses/V_default.png",
          simage: "../assets/img/small-poses/V_hover.png",
          audio:
            "../assets/sound/ninja/" +
            localStorage.getItem("selected_pose") +
            "/3.mp3",
          dancinggif:"../assets/img/small-poses/V_default.gif",
        },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("selected_pose") == 1022) {
      this.active_letter = "21_Point_Right";
    } else if (localStorage.getItem("selected_pose") == 1021) {
      this.active_letter = "21_Point_Left";
    } else if (localStorage.getItem("selected_pose") == 1017) {
      this.active_letter = "17_Point";
    } else if (localStorage.getItem("selected_pose") == 1011) {
      this.active_letter = "11_Point";
    } else if (localStorage.getItem("selected_pose") == 103) {
      this.active_letter = "2_Point";
    } else {
      this.active_letter = localStorage.getItem("selected_pose");
    }
    // this.active_letter = localStorage.getItem('selected_pose');

    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js";
    script.async = true;
    document.body.appendChild(script);

    // part 2

    const script2 = document.createElement("script");
    script2.src = "../assets/js/slick.min.js";
    script2.async = true;
    document.body.appendChild(script2);

    // part 3

    const script3 = document.createElement("script");
    script3.src = "../assets/js/main.js";
    script3.async = true;
    document.body.appendChild(script3);
  },
  created() {
    if (localStorage.getItem("selected_pose") == 1022) {
      this.active_letter = "21_Point_Right";
    } else if (localStorage.getItem("selected_pose") == 1021) {
      this.active_letter = "21_Point_Left";
    } else if (localStorage.getItem("selected_pose") == 1017) {
      this.active_letter = "17_Point";
    } else if (localStorage.getItem("selected_pose") == 1011) {
      this.active_letter = "11_Point";
    } else if (localStorage.getItem("selected_pose") == 103) {
      this.active_letter = "2_Point";
    } else {
      this.active_letter = localStorage.getItem("selected_pose");
    }
    if (localStorage.getItem("selected_pose") == 1) {
      // Remove last index from array
      this.thumbsForButtom.splice(5, 1);
      /*  console.log("length - ",this.thumbsForButtom.length);
      console.log("arrays - ",this.thumbsForButtom.splice(5, 1)); */
    }
  },
  methods: {
    button_actions() {
      // setTimeout( () => this.$router.push({ path: '/letters'}), 0);
    },
    characters(posId, sound, imgOfPose, dancingGif) {
      console.log(imgOfPose+"----gif - ",dancingGif);
      var finalpathOfPose = imgOfPose.substring(3);

      this.selectedposesWithSound_array.push({
        sound: sound,
        image: finalpathOfPose,
      });
      this.selectedposes_array.push(finalpathOfPose);

      if (this.selectedposes_array.length == 3) {
        this.selectedposes_array.push(
          "assets/img/letters/big-letters/" + this.active_letter + ".png"
        );
        this.selectedposesWithSound_array.push({
          sound: "",
          image:
            "assets/img/letters/big-letters/" + this.active_letter + ".png",
        });
      }
      // var incse = (this.thumbsForTop.length==0)?5:5-this.thumbsForTop.length;
      localStorage.setItem("poseonebysound_" + this.thumbsForTop.length, sound);
      

      if (this.thumbsForTop.length == 5) {
        this.isSelectionProcessCompleted = true;
        this.hideSelectedLetterIfSelectionCompleted=false;
      }
      if (this.thumbsForTop.length < 6) {
        /* localStorage.setItem(
          "selectedpreivousposes",
          JSON.stringify(this.selectedposes_array)
        ); */
        localStorage.setItem(
          "selectedpreivousposesWithSound",
          JSON.stringify(this.selectedposesWithSound_array)
        );
        localStorage.setItem("dancing_sound_gif"+ this.thumbsForTop.length, dancingGif);
        // Sound audio
        if (this.removedSelection.length > 0) {
          /* this.thumbsForTop.splice(this.removedSelection[0], 0, {
            id: this.thumbsForTop.length,
            image: "../assets/img/small-poses/pose" + posId + ".png",
          }); */
          //this.removedSelection.splice(this.removedSelection[0], 1);
        } else {
          this.thumbsForTop.push({
            id: this.thumbsForTop.length,
            image: "../assets/img/small-poses/pose" + posId + ".png",
          });
        }
        console.log("auod-", sound);
        if (sound) {
          var audio = new Audio(sound);
          audio.play();
        }
      } else {
        // this.isSelectionProcessCompleted = true;
        /*if(confirm('Are you sure, want to checkout final?')){
              setTimeout( () => this.$router.push({ path: '/demo'}), 0);
          }else{
          console.log("cancelled");
          }*/
      }
    },
    dancingFace() {
      // playing all selected 6 sound
      setTimeout(function () {
        var bMusic = new Audio(localStorage.getItem("poseonebysound_5"));
        bMusic.play();
      }, 5000);
      setTimeout(function () {
        var bMusic = new Audio(localStorage.getItem("poseonebysound_4"));
        bMusic.play();
      }, 7000);
      setTimeout(function () {
        var bMusic = new Audio(localStorage.getItem("poseonebysound_3"));
        bMusic.play();
      }, 10000);
      setTimeout(function () {
        var bMusic = new Audio(localStorage.getItem("poseonebysound_2"));
        bMusic.play();
      }, 12000);
      setTimeout(function () {
        var bMusic = new Audio(localStorage.getItem("poseonebysound_1"));
        bMusic.play();
      }, 14000);
      setTimeout(function () {
        var bMusic = new Audio(localStorage.getItem("poseonebysound_0"));
        bMusic.play();
      }, 16000);
      // sound end
      var soundurl =
        "../assets/sound/ninja/" +
        localStorage.getItem("selected_pose") +
        "/selected.wav";
      console.log("sound supporting...", soundurl);
      var audio = new Audio(soundurl);
      audio.play();

      // Final bg sound
      // sound end
      var fbgs =
        "../assets/sound/common/background_final.wav";
      
        if(localStorage.getItem('happy_page_bg_audio_series')==1){
            localStorage.setItem("happy_page_bg_audio_series", 2);
            fbgs ="../assets/sound/common/background_final.wav";
          }else{
            localStorage.setItem("happy_page_bg_audio_series", 1);
            fbgs ="../assets/sound/common/background_final_2.wav";
          }
          console.log("fbgs = ",fbgs);
      var fbgs_audio = new Audio(fbgs);
          fbgs_audio.volume = 0.1;
          fbgs_audio.play();
          setTimeout(function () {
            fbgs_audio.pause();
      }, 25000);
      setTimeout(() => this.$router.push({ path: "/demo" }), 0);
    },
    removeSelection(selectedArray,index) {
      console.log(selectedArray);
      // reset 
      this.selectedposesWithSound_array.pop(index);
      this.thumbsForTop.splice(index, 1);
      // this.selectedposes_array.splice(index,1);
      // hide play button
      this.isSelectionProcessCompleted = false;
      this.hideSelectedLetterIfSelectionCompleted=true;

      console.log("led-",this.selectedposesWithSound_array);
      // alert("Selected Pose Delete");
    },
  },
};
</script>

<template>
<section class="main">
   <div class="back-arrow nav-arrow">
        <a href="#"><img src="../assets/img/back-arrow.png" alt="" /></a>
      </div>
      <div class="forward-arrow nav-arrow">
         <a href="#"><img src="../assets/img/forward-arrow.png" alt="" /></a>
      </div>


  <div class="speaker"> 
  <div class="character-stage-poses">
       <!--  <div v-for="(poserow, index) in selectionList" :key="index" :id="'reserve' + index"  :class='index==3 ?"char-small-char":"char-small-poses"'><a href="#" :ref="'myBtn' + index">
          <img v-if="isPlayActionActive==0" :src='poserow.image' :alt='poserow.image' class="posesnormal_1" :data-id="index" >
        </a></div> -->
        <div v-if="isPlayActionActive==0">
          <div id="reserve0" class="char-small-poses" v-if="posFirstImage">
          <a href="#">
            <img :src="posFirstImage" :alt="posFirstImage" data-id="0">
          </a>
        </div>
        <div id="reserve1" class="char-small-poses" v-if="posSecondImage">
          <a href="#">
            <img :src="posSecondImage" :alt="posSecondImage" data-id="1">
          </a>
        </div>
        <div id="reserve2" class="char-small-poses" v-if="posThirdImage">
          <a href="#">
            <img :src="posThirdImage" :alt="posThirdImage" data-id="2">
          </a>
        </div>
        <div id="reserve3" class="char-small-char" v-if="posCharactdImage">
          <a href="#">
            <img :src="posCharactdImage" :alt="posCharactdImage" data-id="3">
          </a>
        </div>
        <div id="reserve4" class="char-small-poses" v-if="posFourImage">
          <a href="#">
            <img :src="posFourImage" :alt="posFourImage" data-id="4">
          </a>
        </div>
        <div id="reserve5" class="char-small-poses" v-if="posFifthImage">
          <a href="#">
            <img :src="posFifthImage" :alt="posFifthImage" data-id="5">
          </a>
        </div>
        <div id="reserve6" class="char-small-poses" v-if="posSixImage">
          <a href="#">
            <img :src="posSixImage" :alt="posSixImage" data-id="6">
          </a>
        </div>
        </div>
      </div>
     <!--  <div class="playpause" style="width: 220px;"></div> -->
    <div class="pg1-vector-frame" v-show="isPlayActionActive">
      <img src="../assets/img/charactor-selected-magic_transition.gif" style="width:1000px;" alt="" @load="loadImage" />
    </div>
     <div class="pg1-vector-frame" v-show="isPlayActionActive">
      <img :src="gifVideoLoad" style="width:28%;margin: 12% 0 0 0;" alt="" @load="loadImage" />
    </div>
    <div class="pg1-vector-frame" v-show="showDanceImage_5">
      <img :src="gifVideoLoad" style="width:28%;margin: 12% 0 0 0;" alt="" @load="loadImage" />
    </div>
    <div class="pg1-vector-frame" v-show="showDanceImage_4">
      <img :src="gifVideoLoad" style="width:28%;margin: 12% 0 0 0;" alt="" @load="loadImage" />
    </div>
    <div class="pg1-vector-frame" v-show="showDanceImage_3">
      <img :src="gifVideoLoad" style="width:28%;margin: 12% 0 0 0;" alt="" @load="loadImage" />
    </div>
    <div class="pg1-vector-frame" v-show="showDanceImage_2">
      <img :src="gifVideoLoad" style="width:28%;margin: 12% 0 0 0;" alt="" @load="loadImage" />
    </div>
    <div class="pg1-vector-frame" v-show="showDanceImage_1">
      <img :src="gifVideoLoad" style="width:28%;margin: 12% 0 0 0;" alt="" @load="loadImage" />
    </div>
    <div class="pg1-vector-frame" v-show="showDanceImage_0">
      <img :src="gifVideoLoad" style="width:28%;margin: 12% 0 0 0;" alt="" @load="loadImage" />
    </div>
</div>



<div v-show="isAllsoundFinished">
<div class="demo-replay demo-buttons">
  <a href="#" @click="enternow"><img src="../assets/img/replay-button.png" alt="" /></a>
</div>

<div class="demo-home demo-buttons">
  <a href="/"><img src="../assets/img/exit-home-button.png" alt="" /></a>
</div>
</div>

</section>
</template>
<script>
export default {
  name: "Intro",
   data() {
    return {
      isLoaded: false,
      videoElement: null,
      paused: null,
      isPlayActionActive : true,
      isshowVideo : false,
      selectionList:[],
      firstGif:false,
      gifVideoLoad:this.gifVideoLoad=localStorage.getItem("dancing_sound_gif5"),
      showDanceImage_5 :false,
      showDanceImage_4 :false,
      showDanceImage_3 :false,
      showDanceImage_2 :false,
      showDanceImage_1 :false,
      showDanceImage_0 :false,
      isAllsoundFinished: false,
      storedLocaLSoundPoses : localStorage.getItem('selectedpreivousposesWithSound'),
      posFirstImage:'',
      posSecondImage:'',
      posThirdImage:'',
      posCharactdImage:'',
      posFourImage:'',
      posFifthImage:'',
      posSixImage:'',
    };
  },
  mounted() {
   setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.showDanceImage_5=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif5")
                }, 4300);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.showDanceImage_5=false;
                    this.showDanceImage_4=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif4")
                }, 6500);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.showDanceImage_5=false;
                    this.showDanceImage_4=false;
                    this.showDanceImage_3=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif3")
                }, 9000);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.showDanceImage_5=false;
                    this.showDanceImage_4=false;
                    this.showDanceImage_3=false;
                    this.showDanceImage_2=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif2")
                }, 11000);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.showDanceImage_5=false;
                    this.showDanceImage_4=false;
                    this.showDanceImage_3=false;
                    this.showDanceImage_2=false;
                    this.showDanceImage_1=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif1")
                }, 13000);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.showDanceImage_5=false;
                    this.showDanceImage_4=false;
                    this.showDanceImage_3=false;
                    this.showDanceImage_2=false;
                    this.showDanceImage_1=false;
                    this.showDanceImage_0=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif0")
                    this.isAllsoundFinished=true;
                }, 15000);
  },
  created(){
      var converArrayOfStoredImage = JSON.parse(this.storedLocaLSoundPoses);
      // setTimeout( () => this.isPlayActionActive=false, 2000);
      setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.firstGif=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif5")
                    this.posSixImage = this.getHoverImgPose(converArrayOfStoredImage[6]['image']);
                }, 4300);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.firstGif=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif4")
                    this.posSixImage = converArrayOfStoredImage[6]['image'];
                    this.posFifthImage = this.getHoverImgPose(converArrayOfStoredImage[5]['image']);
                }, 6500);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.firstGif=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif3")
                    this.posFifthImage = converArrayOfStoredImage[5]['image'];
                    this.posFourImage = this.getHoverImgPose(converArrayOfStoredImage[4]['image']);
                }, 9000);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.firstGif=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif2")
                    console.log("4-",converArrayOfStoredImage[4]['image']);
                    this.posFourImage = converArrayOfStoredImage[4]['image'];
                    this.posThirdImage = this.getHoverImgPose(converArrayOfStoredImage[2]['image']);
                }, 11000);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.firstGif=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif1")
                    this.posThirdImage = converArrayOfStoredImage[2]['image'];
                    this.posSecondImage = this.getHoverImgPose(converArrayOfStoredImage[1]['image']);
                }, 13000);
                setTimeout(() =>{
                    this.isPlayActionActive=false;
                    this.isshowVideo=true;
                    this.firstGif=true;
                    this.gifVideoLoad=localStorage.getItem("dancing_sound_gif0")
                    this.isAllsoundFinished=true;
                    this.posSecondImage = converArrayOfStoredImage[1]['image'];
                    this.posFirstImage = this.getHoverImgPose(converArrayOfStoredImage[0]['image']);
                }, 15000);
      this.storedposeData();
  },
  methods: { 
     enternow() {
     this.$router.push("/character");
   },
   getHoverImgPose(posimg){
      return posimg.replace('_default.png','_hover.png');
   },
    storedposeData(){
        // var getstoredata =  localStorage.getItem('selectedpreivousposesWithSound');
        var converArrayOfStoredImage = JSON.parse(this.storedLocaLSoundPoses);
        this.selectionList = converArrayOfStoredImage;
        
        // Deviding manually 
        this.posFirstImage = converArrayOfStoredImage[0]['image'];
        this.posSecondImage = converArrayOfStoredImage[1]['image'];
        this.posThirdImage = converArrayOfStoredImage[2]['image'];
        this.posCharactdImage = converArrayOfStoredImage[3]['image'];
        this.posFourImage = converArrayOfStoredImage[4]['image'];
        this.posFifthImage = converArrayOfStoredImage[5]['image'];
        this.posSixImage = converArrayOfStoredImage[6]['image'];
        
    },
    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },
    play() {
      this.videoElement.play();
    },
    pause() {
      this.videoElement.pause();
    },
    playsoundAudio(sound){
      console.log("sound - ",sound);
      if(sound) {
            var audio = new Audio(sound);
            audio.play();
          }
    }
  }
};
</script>
<style>
.video1{
width:28%;
}
</style>
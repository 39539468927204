<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
      <button
            type="button"
            class="close-btn"
            @click="close"
            aria-label="Close Modal"
          >
            x
          </button>
        <header class="modal-header" id="modalTitle">
          <slot name="header"> Default Header </slot>
          
        </header>

        <main class="modal-body" id="modalDescription">
          <slot name="body"> Default body content </slot>
        </main>

        <!-- <footer class="modal-footer">
          <slot name="footer"> Default Footer! </slot>
          <button
            type="button"
            class="btn-open-modal"
            @click="close"
            aria-label="Close Modal"
          >
            Close Modal
          </button>
        </footer> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 50%;
      font-family: 'mriam';
      border:50px solid #65acdb;
      position: relative;
}

.modal-header,
.modal-footer {
  padding: 20px 15px 20px 15px;
  display: flex;
  font-size:30px;
}

.modal-header {
  position: relative;
  border-bottom: 0px solid rgb(227, 231, 233);
  color:#7f0100;
  justify-content: space-between;
  text-align:center;
  align-self:center;
  padding-bottom:0px;
  padding-top:40px;
}

.modal-footer {
  border-top: 1px solid rgb(227, 231, 233);
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding:30px;
  color: #7f0100;
  font-size: 18px;
  line-height: 1.5;
  font-family: 'mriam';
  text-align:center;
}

.close-btn {
  position: absolute;
    top: -38px;
    right: -39px;
    border: none;
    font-size: 25px;
    padding: 0;
    cursor: pointer;
    font-weight: bold;
    color: #7f0100;
    background: transparent;
    border: 1px solid #7f0100;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    line-height: 10px;
    text-align: center;
}

.btn-open-modal {
  color: white;
  background: green;
  border: 1px solid green;
  border-radius: 4px;
  margin: 20px auto;
  padding: 5px;
  width: 40%;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
<template>
  <Modal v-show="visible" @close="close" id="modelapenders">
    <template v-slot:header> {{slotContentsHeading}} </template>
    <template v-slot:body> {{slotContents}} </template>
    <!-- <template v-slot:footer> You can put your footer here </template> -->
  </Modal>

  <header class="header">
    <a href="" class="logo-new"
      ><img src="../assets/img/hub-logo.png" alt=""
    /></a>
    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn"
      ><span class="navicon"></span
    ></label>
     <!-- <ul class="menu"> -->
      <!--<li><a href="#" @click="openModal(cmsContents[0]['title'],cmsContents[0]['contents'])">אודות</a></li>
      <li class="mob-hide">
        <img src="../assets/img/menu-mid-shadow.png" alt="" />
      </li>
      <li><a href="#" @click="openModal(cmsContents[1]['title'],cmsContents[1]['contents'])">סיפורי הצלחה</a></li>
      <li class="mob-hide">
        <img src="../assets/img/menu-mid-shadow.png" alt="" />
      </li>
      <li><a href="#" @click="openModal(cmsContents[2]['title'],cmsContents[2]['contents'])">הדרכה</a></li>
      <li class="mob-hide">
        <img src="../assets/img/menu-mid-shadow.png" alt="" />
      </li>
      <li><a href="#" @click="openModal(cmsContents[3]['title'],cmsContents[3]['contents'])">צור קשר</a></li> -->
      <div class="btn-sep">
        <a href="/intro"  class="login"
          ><img src="../assets/img/green-btn.png" alt=""
        /></a>
      </div>
    <!-- </ul> -->
  </header>
  <div class="newaloon"><img src="../assets/img/baloons-bg.png" alt="" /></div>
  <!-- <div class="pg1-sign-banner"><img src="../assets/img/Sign.png" alt="" /></div> -->
  <div class="main-container">
    <div class="pagehb-mainbg">
      <div class="hp-nikudu-image">
        <img src="../assets/img/stand-nikudu.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
// import Home from "../components/Homepage.vue";

export default {
  name: "App",
  components: {
    Modal,
  },
  data() {
    return {
      introPageSoundurl: "../assets/sound/common/bg_letters.wav", // just loading by defualt this for next page 
      introVideoPageSoundurl: "../assets/img/nikudu-dance.mp4", // just loading by defualt this for next page 
      loadFirstsoundIntro:"../assets/sound/common/intro_with_magic.wav",
      videoElement: null,
      visible: false,
      slotContents:'',
      slotContentsHeading:'',
      cmsContents:[
        {
          'title':'אודות',
          'contents':'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
        },
        {
          'title':'סיפורי הצלחה',
          'contents':'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
        },
        {
          'title':'3333',
          'contents':'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
        },
        {
          'title':'44444',
          'contents':'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
        }
      ]
    };
  },
  methods: {
    openModal(slotheader, slotContents) {
      this.slotContents=slotContents;
      this.slotContentsHeading=slotheader
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    loadImage() {
      this.isLoaded = true;
      // setTimeout( () => this.$router.push({ path: '/intro'}), 5000);
      // this.$router.push("/character");
    },
    enternow() {
     
      this.$router.push("/intro");
    },
    bgPlay() {
      /* var fbgs = "../assets/sound/common/background_final.wav";
      var fbgs_audio = new Audio(fbgs);
      fbgs_audio.volume = 0.1;
      fbgs_audio.play();
      setTimeout(function () {
        fbgs_audio.pause();
      }, 25000); */
    },
    pauseaudio(){
      var audio = new Audio();
      audio.src = "../assets/sound/common/background_final.wav";
      audio.play();
    }
  },
  created() {
    /*var audio = new Audio();
    audio.src = "../assets/sound/common/background_final.wav";
    // when the sound has been loaded, execute your code
    audio.oncanplaythrough = () => {
      var playedPromise = audio.play();
      audio.volume = 0.5;
      if (playedPromise) {
        playedPromise
          .catch((e) => {
            console.log(e);
            if (
              e.name === "NotAllowedError" ||
              e.name === "NotSupportedError"
            ) {
              console.log(e.name);
            }
          })
          .then(() => {
            console.log("playing sound !!!");
          });
      }
    };*/
  },
};
</script>

<style>
.btn {
  background-color: rgb(96, 96, 214);
  color: #fff;
  border-radius: 4px;
  padding: 8px;
  border: none;
  font-weight: bold;
}
.btn-sep{
  margin-top:-8px;
  text-align:left;
}
</style>

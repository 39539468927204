<template>
  <div class="video-wrapper">
     <div class="back-arrow nav-arrow back-arrow-top">
        <a href="/" v-if="magicshow==0"><img src="../assets/img/back-arrow.png" alt="" /></a>
      </div>
      <div class="forward-arrow nav-arrow back-arrow-top">
         <a href="#" @click.prevent="button_actions('nextpage')" v-if="magicshow==0"><img src="../assets/img/forward-arrow.png" alt="" /></a>
      </div>
  <!-- <div class="skip-button" v-show="videoAction" @click.prevent="button_actions('nextpage')"></div> -->
  <!-- <div class="back-button" @click.prevent="button_actions('backpage')"></div> -->
    <video v-show="videoActionImage" class="video" id="myVideo" poster="../assets/img/video-planebg.jpg">
        <source src="assets/img/nikudu-dance.mp4" type="video/mp4">
    </video>
    <video v-show="videoAction" class="video" id="videoElement" @canplay="updatePaused" @playing="updatePaused" @pause="updatePaused">
      <source src="../assets/img/nikudu-dance.mp4" type="video/mp4" @load="loadImage" />
    </video>
    <div class="playpause" v-show="paused" @click="play"></div>
    <div class="roundermagic" v-show="magicshow"></div>
  </div>
</template>
<script>
export default {
  name: "Intro",
   data() {
    return {
      isLoaded: false,
      videoElement: null,
      paused: null,
      isPlayActionActive : false,
      videoAction: false,
      videoActionImage: true,
      magicshow:false,
      timerSetup : setTimeout( () => this.button_actions('nextpage'), 33000),
      //audiotimerSetup : setTimeout( () => this.letterPageForSound(33000), 0),
    };
  },
  mounted() {
    const script = document.createElement("script")
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"
      script.async = true
      document.body.appendChild(script)

      // part 2
      
      const script2 = document.createElement("script")
      script2.src = "../assets/js/slick.min.js"
      script2.async = true
      document.body.appendChild(script2)

      // part 3

      const script3 = document.createElement("script")
      script3.src = "../assets/js/main.js"
      script3.async = true
      document.body.appendChild(script3)
  },
  methods: {
  button_actions (type) {
    if(type=='nextpage'){
        this.letterPageForSound(1000);
        clearTimeout(this.timerSetup);
        setTimeout( () => this.$router.push({ path: '/letters','title': 'test title'}), 0);
        // this.$router.push("/letters");
    }
    if(type=='backpage'){
        setTimeout( () => this.$router.push({ path: '/'}), 0);
    }
        
  },
    loadImage() {
      this.isLoaded = true;
    },
    updatePaused(event) {
      if(this.isPlayActionActive==true){
          // this.letterPageForSound(30000); // enable if need auto page redirect and sound should play
          this.timerSetup; // page redirect in the letters
      }
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },
    play() {
      this.magicshow=true;
      this.paused=false;
      this.videoElement.play();
      setTimeout(() =>{
        this.magicshow=false;
        this.videoElement.play();
        this.isPlayActionActive=true;
        this.videoAction = true;
        this.videoActionImage = false;
        }, 2000);
    },
    pause() {
      this.videoElement.pause();
    },
    letterPageForSound (timeDefine=3000){
      setTimeout(() =>{
          var soundurl = "../assets/sound/common/bg_letters.wav";
          var audio = new Audio(soundurl);
              audio.play();
        }, timeDefine);
    }
  },
  computed: {
    playing() { 
      console.log("ehllo");
    return !this.paused; 
    
    }
  },
  created(){
    this.magicshow=true;
    this.paused=false;

    var soundurl = "../assets/sound/common/intro_with_magic.wav";
    var audio = new Audio(soundurl);
        audio.play();

      setTimeout(() =>{
        audio.pause();
        this.magicshow=false;
        this.videoElement.play();
        this.isPlayActionActive=true;
        this.videoAction = true;
        this.videoActionImage = false;
        }, 4000);
  }
};
</script>
<style>
@import 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick-theme.min.css';
</style>